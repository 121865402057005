import moment from "moment";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, DatePicker, Loader } from "rsuite";

import { Locale } from "../../utils";
import { checkSignable, contractSettings } from "../../utils/sign";

import Input from "../Input";
import Select from "../Select";
import EquipmentSet from "./EquipmentSet";
import FileService from "../../services/fileService";

const RenewalForm = ({
  prescription,
  prescriber,
  prescribers,
  onSubmit,
  setSignatureLink,
  printMode,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [finessItems, setFinessItems] = useState([]);
  const [currentFinessItem, setCurrentFinessItem] = useState({
    label: "Autre",
    value: "other",
  });
  const [otherFiness, setOtherFiness] = useState(false);
  const [prescriberItems, setPrescriberItems] = useState([]);
  const [prescriberItem, setPrescriberItem] = useState(prescriber);
  const [currentPrescriberItem, setCurrentPrescriberItem] =
    useState(prescriber);
  const [careSetChecked, setCareSetChecked] = useState(true);
  const [tegaderm6X7Checked, setTegaderm6X7Checked] = useState(true);
  const [tegaderm9X10Checked, setTegaderm9X10Checked] = useState(true);
  const [missingDataChecked, setMissingDataChecked] = useState(false);
  const [isDateLocked] = useState(true);
  const [settings, setSettings] = useState({
    enabled: false,
    label: "",
    items: {
      careSet: { display: false, default: 1 },
      tegaderm6X7: { display: false, default: 1 },
      tegaderm9X10: { display: false, default: 1 },
      catheter: { display: false, default: "" },
      autocontrol: { display: false, default: false },
    },
  });

  const fileService = new FileService();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm();

  useEffect(() => {
    const items = [];
    prescribers.map((p) => {
      items.push({ label: `${p.user?.fullName} - ${p.rpps}`, value: p._id });
      return p;
    });
    setPrescriberItems(items);
  }, [prescribers]);

  useEffect(() => {
    setSettings(contractSettings(prescription.contract.plan.code));
    if (isDateLocked) {
      setValue("startDate", new Date(prescription.startDate));
      setValue("endDate", new Date(prescription.endDate));
    }
    setIsLoading(false);
  }, [
    prescription.contract.plan.code,
    isDateLocked,
    setValue,
    getValues,
    prescription.startDate,
    prescription.endDate,
  ]);

  const formatFinessItems = (finess) => {
    const items = [];
    finess.map((f, index) => {
      items.push({
        label: `${f.finnesNumber} - ${f.name}`,
        value: String(index),
      });
      return f;
    });
    return items;
  };

  useEffect(() => {
    const defaultFinessItems =
      prescriber.finess &&
      Array.isArray(prescriber.finess) &&
      prescriber.finess.length > 0
        ? formatFinessItems(prescriber.finess)
        : [];

    defaultFinessItems.push({ label: "Autre", value: "other" });

    setFinessItems(defaultFinessItems);
    setCurrentFinessItem(defaultFinessItems[0]);
    if (defaultFinessItems.length === 1) setOtherFiness(true);

    const defaultValues = {
      startDate: new Date(prescription.startDate),
      endDate: new Date(prescription.endDate),
      finess:
        defaultFinessItems[0].value !== "other"
          ? defaultFinessItems[0].label
          : null,
      finessOther: "",
      socialReason: "",
      ...Object.keys(settings.items).reduce((acc, key) => {
        if (settings.items[key].display) {
          acc[key] = settings.items[key].default;
        }
        return acc;
      }, {}),
    };

    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriber.finess, settings]);

  if (!settings.enabled) {
    return (
      <div className="flex justify-center text-primary p-3">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            Le renouvellement du type{" "}
            <span className="font-bold text-dark">
              {prescription.contract.plan.code}
            </span>{" "}
            n'est pas disponible sur le site pour le moment.
          </div>
        )}
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        try {
          console.log({ code: prescription.contract.plan.code, ...data });
          if (
            checkSignable({ code: prescription.contract.plan.code, ...data })
          ) {
            setMissingDataChecked(false);
            if (!data.finess) {
              data.finess = `${data.finessOther} - ${data.socialReason}`;
            }
            const result = await onSubmit(prescription._id, {
              prescriber: prescriberItem,
              ...data,
            });

            if (result.data && result.data.signatureLink) {
              setSignatureLink(result.data.signatureLink);
            } else {
              await fileService.downloadFile(result);
            }
          } else {
            setMissingDataChecked(true);
          }
        } catch (error) {}
      })}
      className="flex flex-col justify-center gap-5 p-3"
    >
      <div className="flex flex-col">
        <span>
          Vous vous apprêtez à renouveler la prescription de{" "}
          <span className="font-semibold">{settings.label}</span> pour le
          patient <span className="font-semibold">{prescription.patient}</span>{" "}
          <span>pour la période suivante :</span>
        </span>
        <div className="flex gap-5 py-3 border-b border-b-gray-300">
          <div
            className={`flex ${
              isDateLocked ? "flex-row items-center gap-1" : "flex-col"
            }`}
          >
            <label className="text-xs font-semibold text-neutral-dark">
              Date de début<span className="text-red-500">*</span> :
            </label>
            <Controller
              control={control}
              name="startDate"
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return isDateLocked ? (
                  <div>
                    {moment(getValues("startDate")).format("DD/MM/YYYY")}
                  </div>
                ) : (
                  <>
                    <DatePicker
                      locale={Locale}
                      format="dd/MM/yyyy"
                      placeholder="Choisir une date"
                      size="sm"
                      block
                      value={field.value}
                      onChange={(date) => {
                        setValue("startDate", date);
                        setValue(
                          "endDate",
                          date
                            ? moment(date)
                                .subtract(1, "days")
                                .add(6, "months")
                                .toDate()
                            : null
                        );
                        clearErrors(["startDate", "endDate"]);
                      }}
                      onClean={() => {
                        setValue("startDate", null);
                        setValue("endDate", null);
                        setError(
                          "startDate",
                          {
                            type: "manual",
                            message: "La date de début ne peut pas être nulle.",
                          },
                          { shouldFocus: true }
                        );
                        console.log("errors", errors);
                      }}
                    />
                    {!field.value && (
                      <div className="text-xs text-red-500">
                        Veuillez saisir une date de début.
                      </div>
                    )}
                  </>
                );
              }}
            />
          </div>
          <div
            className={`flex ${
              isDateLocked ? "flex-row items-center gap-1" : "flex-col"
            }`}
          >
            <label className="text-xs font-semibold text-neutral-dark">
              Date de fin<span className="text-red-500">*</span> :
            </label>
            <Controller
              control={control}
              name="endDate"
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return isDateLocked ? (
                  <div>{moment(prescription.endDate).format("DD/MM/YYYY")}</div>
                ) : (
                  <>
                    <DatePicker
                      locale={Locale}
                      format="dd/MM/yyyy"
                      placeholder="Choisir une date"
                      className="w-fit"
                      size="sm"
                      block
                      value={field.value}
                      onChange={(date) => {
                        const startDate = getValues("startDate");
                        if (startDate && date && date <= startDate) {
                          setError(
                            "endDate",
                            {
                              type: "manual",
                              message:
                                "La date de fin doit être supérieure à la date de début.",
                            },
                            { shouldFocus: true }
                          );
                        } else if (!date) {
                          setError(
                            "endDate",
                            {
                              type: "manual",
                              message: "La date de fin ne peut pas être nulle.",
                            },
                            { shouldFocus: true }
                          );
                        } else if (!moment(date).isValid()) {
                          setError(
                            "endDate",
                            {
                              type: "manual",
                              message:
                                "La date de fin doit être une date valide.",
                            },
                            { shouldFocus: true }
                          );
                        } else {
                          clearErrors("endDate");
                        }
                        setValue("endDate", date);
                      }}
                      onClean={() => {
                        clearErrors("endDate");
                        setValue("endDate", null);
                      }}
                    />
                    {!field.value && (
                      <div className="text-xs text-red-500">
                        Veuillez saisir une date de fin.
                      </div>
                    )}
                    {errors.endDate && (
                      <div className="text-xs text-red-500">
                        {errors.endDate.message}
                      </div>
                    )}
                  </>
                );
              }}
            />
          </div>
        </div>
        {printMode &&
          (Array.isArray(prescriberItems) && prescriberItems.length > 1 ? (
            <div className="flex flex-col gap-2 py-3 border-b border-b-gray-300">
              <p>
                Vous allez générer un renouvellement avec le nom du prescripteur
                :
              </p>
              <Select
                data={prescriberItems}
                placeholder="Prescripteur"
                value={currentPrescriberItem}
                onChange={(value) => {
                  const item = _.find(prescribers, { _id: Number(value) });
                  const newFiness = formatFinessItems(item.finess);
                  newFiness.push({ label: "Autre", value: "other" });
                  setPrescriberItem(item);
                  setCurrentPrescriberItem(value);
                  setFinessItems(newFiness);

                  if (newFiness.length > 1) {
                    setValue("finess", newFiness[0].label);
                    setCurrentFinessItem(newFiness[0]);
                    setOtherFiness(false);
                  } else {
                    setValue("finess", null);
                    setCurrentFinessItem({ label: "Autre", value: "other" });
                    setOtherFiness(true);
                  }
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-2 py-3 border-b border-b-gray-300">
              <p>
                Vous allez générer un renouvellement avec le nom du prescripteur{" "}
                <span className="font-semibold">
                  {prescriber?.user?.fullName}
                </span>
              </p>
            </div>
          ))}
        <div className="flex flex-col gap-2 py-3 border-b border-b-gray-300">
          <p>
            Veuillez selectionner le <strong>finess</strong>
            <span className="text-red-500">*</span>
          </p>
          <Select
            data={finessItems}
            placeholder="Finess"
            value={currentFinessItem}
            onChange={(value) => {
              const currentItem = finessItems.find(
                (item) => item.value === value
              );
              if (value !== "other") {
                setValue("finess", currentItem.label);
                setCurrentFinessItem(currentItem);
                setOtherFiness(false);
              } else {
                setValue("finess", null);
                setCurrentFinessItem({ label: "Autre", value: "other" });
                setOtherFiness(true);
              }
            }}
          />
          {otherFiness && (
            <div className="flex flex-col justify-between gap-2 sm:flex-row">
              <div className="w-full">
                <p className="">
                  Code finess ou numéro Adeli
                  <span className="text-red-500">*</span>
                </p>
                <Controller
                  control={control}
                  name="finessOther"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      placeholder="Code finess ou numéro Adeli"
                      size="sm"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      error={
                        !field.value ||
                        (typeof field.value === "string" &&
                          field.value.trim() === "")
                          ? {
                              message:
                                "Veuillez saisir un code finess ou un numéro Adeli.",
                            }
                          : null
                      }
                    />
                  )}
                />
              </div>

              <div className="w-full">
                <p className="">
                  Raison sociale de l'établissement
                  <span className="text-red-500">*</span>
                </p>
                <Controller
                  control={control}
                  name="socialReason"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      placeholder="Raison sociale de l'établissement"
                      size="sm"
                      value={field.value}
                      onChange={field.onChange}
                      error={
                        !field.value ||
                        (typeof field.value === "string" &&
                          field.value.trim() === "")
                          ? {
                              message:
                                "Veuillez saisir la raison sociale de l'établissement.",
                            }
                          : null
                      }
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col border-b border-b-gray-300">
          {settings.items.careSet.display && (
            <EquipmentSet
              className="border-b border-b-gray-300"
              name="careSet"
              label="set de soins"
              required
              control={control}
              checked={careSetChecked}
              setChecked={setCareSetChecked}
              setValue={setValue}
              defaultValue={settings.items.careSet.default}
            />
          )}
          {settings.items.tegaderm6X7.display && (
            <EquipmentSet
              className="border-b border-b-gray-300"
              name="tegaderm6X7"
              label="tegaderm 6x7cm"
              required
              control={control}
              checked={tegaderm6X7Checked}
              setChecked={setTegaderm6X7Checked}
              setValue={setValue}
              defaultValue={settings.items.tegaderm6X7.default}
            />
          )}
          {settings.items.tegaderm9X10.display && (
            <EquipmentSet
              className="border-b border-b-gray-300"
              name="tegaderm9X10"
              label="tegaderm 9x10cm"
              required
              control={control}
              checked={tegaderm9X10Checked}
              setChecked={setTegaderm9X10Checked}
              setValue={setValue}
              defaultValue={settings.items.tegaderm9X10.default}
            />
          )}
          {settings.items.autocontrol.display && (
            <Controller
              control={control}
              name="autocontrol"
              render={({ field }) => (
                <div className="flex items-center py-3">
                  <Checkbox
                    name="autocontrol"
                    checked={field.value}
                    onChange={(value, checked) => field.onChange(checked)}
                  />
                  <p
                    className="cursor-default hover:underline"
                    onClick={() =>
                      setValue("autocontrol", !field.value, {
                        shouldFocus: true,
                      })
                    }
                  >
                    Autocontrôle du glucose interstitiel, forfait mensuel
                    capteurs, MEDTRONIC, ENLITE (LPP 1170862)
                    <span className="text-red-500">*</span>
                  </p>
                </div>
              )}
            />
          )}
        </div>
      </div>
      {missingDataChecked && (
        <div className="text-xs text-red-500">
          Veuillez remplir tous les champs obligatoires.
        </div>
      )}
      <button
        type="submit"
        className="self-end px-3 py-1 font-semibold border rounded w-fit disabled:text-dark border-dark text-dark hover:bg-dark disabled:bg-transparent hover:text-white"
        disabled={isSubmitting || Object.keys(errors).length > 0}
      >
        {isSubmitting ? (
          <Loader />
        ) : printMode ? (
          "Télécharger"
        ) : (
          "Signer le renouvellement"
        )}
      </button>
    </form>
  );
};

export default RenewalForm;

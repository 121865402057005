import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader, Toggle } from "rsuite";

import { useAuth } from "../context/auth-context";
import { useModal } from "../context/modal-context";
import API from "../utils/api";

import Gabarit from "../components/Gabarit";
import ContractLabel from "../components/Patient/ContractLabel";
import DeviceCard from "../components/Patient/DeviceCard";
import InfoCard from "../components/Patient/InfoCard";
import PrescriptionCard from "../components/Patient/PrescriptionCard";
import PatientTab from "../components/Patient/Tab";
import TreatmentCard from "../components/Patient/TreatmentCard";
import ModalTuto from "../components/Patient/Tuto";
import NotFound from "../components/NotFound";

export default function PatientPage() {
  const [patient, setPatient] = useState(null);
  const [prescriber, setPrescriber] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const { id } = useParams();
  let { user } = useAuth();
  const {
    showModal,
    hideModal,
    isModalVisible,
    setModalLabel,
    modalLabel,
    setModalFullScreen,
  } = useModal();

  useEffect(() => {
    async function defaultValue() {
      setIsLoading(true);
      const initPatient = await getPatient(id);
      const initPrescriber = await getPrescriber(initPatient.prescriberId);
      setPatient(initPatient);
      setPrescriber(initPrescriber);
      setIsLoading(false);
    }
    defaultValue();
    // eslint-disable-next-line
  }, [id]);

  const getPatient = async (patientId) => {
    try {
      const response = await API.get(`/patients/${patientId}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getPrescriber = async (prescriberId) => {
    try {
      if (user.role === "prescriber" && user.roleInfo && user.roleInfo.finess) {
        return user.roleInfo;
      } else {
        const response = await API.get(`/prescribers/${prescriberId}`);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSync = async (patientId) => {
    try {
      setIsLoadingSync(true);
      await API.put(`/patients/${patientId}/sync`);
      window.location.href = `/patients/${patientId}`;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSync(false);
    }
  };

  const handleSign = async (prescriptionId, data) => {
    try {
      const config =
        user.role === "prescriber" ? {} : { responseType: "arraybuffer" };
      const response = await API.put(
        `/prescriptions/${prescriptionId}/sign`,
        data,
        config
      );
      return user.role === "prescriber" ? response.data : response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenTutoModal = async () => {
    setModalLabel("Mode démo");
    setModalFullScreen(false);
    showModal(<ModalTuto onCloseModal={hideModal} />);
  };

  const handleSignFinish = () => {
    setIsLoading(true);
    hideModal();
    setTimeout(() => (window.location.href = `/patients/${id}`), 2000);
  };

  if (isLoading) {
    return (
      <Gabarit className="flex items-center justify-center">
        <Loader className="p-4" />
      </Gabarit>
    );
  } else if (!patient) {
    return <NotFound />;
  }

  return (
    <Gabarit>
      <div className="flex flex-col gap-3 p-5 bg-white rounded-lg shadow-xl">
        <div className="flex flex-row justify-between">
          <Toggle
            checked={isModalVisible && modalLabel === "Mode démo"}
            onChange={handleOpenTutoModal}
            checkedChildren="Mode démo"
            unCheckedChildren="Mode démo"
          />
          <ContractLabel type={patient?.contract?.type} />
        </div>
        <div className="flex flex-col justify-between gap-3 lg:flex-row">
          <InfoCard
            patient={patient}
            isAdmin={user.role === "admin"}
            handleSync={handleSync}
            isLoadingSync={isLoadingSync}
          />
          <div className="flex flex-col lg:flex-row gap-3">
            <div className="flex flex-col gap-3">
              <TreatmentCard traitement={patient.traitement} className="" />
              <DeviceCard
                device={patient.pump}
                lastIntervention={patient.lastIntervention}
              />
            </div>
            <div className="flex flex-col gap-3">
              <PrescriptionCard
                prescriptions={patient.currentPrescriptions}
                viewStatusSano={["admin"].includes(user.role)}
              />
              <PrescriptionCard
                renewalMode
                prescriptions={patient.renewablePrescriptions}
                handleSign={handleSign}
                hasSignAccess={["prescriber"].includes(user.role)}
                printMode={["admin", "commercial", "director"].includes(
                  user.role
                )}
                viewStatusSano={["admin"].includes(user.role)}
                prescriber={prescriber}
                prescribers={patient.prescribers}
                handleSignFinish={handleSignFinish}
              />
            </div>
          </div>
        </div>
        <PatientTab
          className="mt-5"
          patient={patient}
          metrics={patient.metrics}
        />
      </div>
    </Gabarit>
  );
}
